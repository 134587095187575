<template>
  <div class="reception">
    <carousel></carousel>
    <div class="hh_live_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <!-- <img src="@/assets/img/studentImg/home/live-icon.png"/> -->
            直播
          </p>
          <router-link to="/liveList" class="hh_span">
            更多
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
        <live-com
          key="liveComp"
          :type="'liveType'"
          :livedata="livedata"
          @getVerCode="getVerCode"
        />
      </div>
    </div>
    <div class="hh_course_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <!-- <img src="@/assets/img/studentImg/home/course-icon.png"/> -->
            点播
          </p>
          <router-link to="/dibbleList" class="hh_span">
            更多
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
        <dibble-com
          key="dibbleComp"
          :type="'dibbleType'"
          :dibbledata="dibbledata"
          @getVerCode="getVerCode"
        />
      </div>
    </div>
    <div class="hh_live_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <!-- <img src="@/assets/img/studentImg/home/live-icon.png"/> -->
            讲师
          </p>
          <router-link to="/famousTeacher" class="hh_span">
            更多
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
        <teacher-com
          key="liveComp"
          :teacherData="teacherData"
        />
      </div>
    </div>
    <!-- 首页考试部分 产品嫌丑 已注释 -->
    <!-- <div class="hh_course_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <img src="@/assets/img/studentImg/home/exam-icon.png"/>
            考试
          </p>
          <router-link to="/exam" class="hh_span">
            更多
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
        <exam-com :examList="examList"></exam-com>
      </div>
    </div> -->
    <div class="hh_course_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <!-- <img src="@/assets/img/studentImg/home/live-icon.png"/> -->
            资讯
          </p>
          <router-link to="/consultList" class="hh_span">
            更多
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
        <consult-home
          key="liveComp"
          :type="'liveType'"
          :subjectList="subjectList"
          :consultDataList="consultDataList"
          @getVerCode="getVerCode"
          @getConsultList="getConsultList"
        />
      </div>
    </div>
    <courseVerificationCode :courseId="courseId"></courseVerificationCode>
  </div>
</template>
<script>
import lecturerAPI from "@apis/backStageEndAPI/lecturerAPI";
import dictionaryAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
import API from "@/apis/studentsEndAPI/liveListAPI";
import consultAPI from "@/apis/backStageEndAPI/consultManageAPI";
import { fetchExamList } from "@/apis/studentsEndAPI/exam/index.js";
import liveCom from "@/components/studentsComponent/liveCom";
import dibbleCom from "@/components/studentsComponent/liveCom";
import teacherCom from "@comps/studentsComponent/teacherCom";
import consultHome from "@comps/studentsComponent/consultHome";
import examCom from "@/components/studentsComponent/examCom";
import carousel from "@/components/studentsComponent/carousel";
import courseVerificationCode from "@/components/backStageComponent/courseVerificationCode";
import CommonUtils from "@/utils";
export default {
  name: "container",
  components: {
    carousel,
    liveCom,
    dibbleCom,
    teacherCom,
    examCom,
    courseVerificationCode,
    consultHome,
  },
  data() {
    return {
      consultDataList: [],
      subjectOne: "",
      subjectList: [],
      courseId: "",
      liveType: 1,
      dibbleType: 2,
      livedata: [],
      dibbledata: [],
      examList: [],
      teacherData: [],
      liveData: {
        courseType: 1, //课程类型
        page: 1,
        pageSize: 8,
        createStartTime: "",
        createEndTime: "",
        state: "",
        subjectId: "", //学科id
        keyword: "",
        labels: "",
      },
      dibbleData: {
        courseType: 2, //课程类型
        page: 1,
        pageSize: 8,
        createStartTime: "",
        createEndTime: "",
        state: "",
        subjectId: "", //学科id
        keyword: "",
        labels: "",
      },
      consultData: {
        page: 1,
        pageSize: 6,
        newsType: "",
      },
      examData: {
        current: 1,
        size: 6,
      },
      teacherForm: {
        teaName: '',
        page: 1,
        pageSize: 8,
      },
    };
  },
  mounted() {
    this.$store.commit("PARENTID", null);
    this.$store.commit("PARENTChiLD", null);
    sessionStorage.removeItem("dibbledata");
    sessionStorage.removeItem("livedata");
    this.init();
    this.dibbleInit();
    this.examInit();
    this.getCourseLabel();
    this.getClientTeacher()
  },
  methods: {
    async getClientTeacher() {
      let res = await lecturerAPI.getClientTeacher(CommonUtils.parseToParams(this.teacherForm))
      if(res && !res.status) {
        this.teacherData = res.dataList
      }
    },
    getCourseLabel() {
      //资讯分类
      dictionaryAPI.getDictionaryData().then((res) => { //"consult_code"
        this.subjectList = res;
        this.subjectOne = res[0].newsType;
        this.getConsultList(res[0].newsType);
      });
    },
    getVerCode(val) {
      this.courseId = val.courseId;
      this.$store.state.isVerifi = true;
    },
    async init() {
      let res = await API.getLiveList(CommonUtils.parseToParams(this.liveData));
      if (res && !res.status) {
        this.livedata = res.dataList;
      }
    },
    async dibbleInit() {
      let res = await API.getLiveList(
        CommonUtils.parseToParams(this.dibbleData)
      );
      if (res && !res.status) {
        this.dibbledata = res.dataList;
      }
    },
    getConsultList(val) {
      this.consultData.newsType = val ? val : '';
      consultAPI
        .studyConsultList(CommonUtils.parseToParams(this.consultData))
        .then((res) => {
          this.consultDataList = res.dataList;
        });
    },
    async examInit() {
      const { data } = await fetchExamList({
        current: this.examData.current,
        size: this.examData.size,
        params: {
          state: "",
        },
      });
      this.examList = data?.records || [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/studentsEndCSS/home";
.reception {
  width: 100%;
}
</style>
