<template>
  <div class="hh_video_wrap">
    <div class="hh_consult">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in subList"
          :key="index"
          :label="item.newsType"
          :name="String(item.newsType)"
        >
          <consult-info
            
            :consult-data="consultList"
          ></consult-info>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import consultInfo from "./consultInfo";
export default {
  name: "consultHome",
  props: ["subjectList", "consultDataList"],
  components: { consultInfo },
  data() {
    return {
      activeName: '',
      subList: [],
      consultList: []
    };
  },
  watch: {
    subjectList: {
      handler(val) {
        if(val) {
          this.subList = val
          this.activeName = val[0].newsType
        } 
      },
      deep: true
    },
    consultDataList: {
      handler(val) {
        if(val) {
          this.consultList = val
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    handleClick(tab) {
      // console.log(this.activeName)
      this.$emit("getConsultList", tab.label);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/studentsEndCSS/home";
.hh_consult {
  position: relative;
  overflow: hidden;
  margin: 15px 7px 0 7px;
  width: 100%;
  height: 362px;
  text-align: center;
  background: #fff;
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.1);
  padding: 20px 30px;
}
::v-deep .el-tabs__item {
  font-size: 16px;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #fff !important;
}
</style>
