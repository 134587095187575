<template>
  <div class="consult_wrap">
    <ul class="hh_consult_title">
      <li
        class="hh_litle"
        @mouseenter="enter(item, index)"
        @click="toDeatil(item)"
        v-for="(item, index) in data"
        :key="index"
      >
        <p class="hh_title_info" :class="{ hh_style: index == current }">
          <span class="hh_txt">{{ item.newsTitle | filterTitle }}</span>
          <span class="hh_times">{{ item.updateTime }}</span>
        </p>
        <p class="hh_icon" :class="{ hh_showicon: index == current }">
          <img src="@/assets/img/studentImg/home/consult_icon.png" alt="" />
        </p>
      </li>
    </ul>
    <el-image
      class="hh_img"
      @click="toImg(imgkey)"
      :key="Number(imgkey)"
      :src="dataImg"
      fit="contain"
    />
  </div>
</template>
<script>

export default {
  name: "consultHome",
  props: ["consultData"],
  data() {
    return {
      current: 0,
      imgkey: 0,
      data: [],
      dataImg: "",
    };
  },
  watch: {
    consultData: {
      handler(newVal) {
        this.data = newVal;
        this.imgkey = newVal[0].id;
        this.dataImg = newVal[0].imgUrl;
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    enter(item, index) {
      this.current = index;
      this.dataImg = item.imgUrl;
      this.imgkey = item.id;
    },
    toDeatil(item) {
      this.$router.push({ path: "/consultDetail", query: { id: item.id } });
    },
    toImg(item) {
      this.$router.push({ path: "/consultDetail", query: { id: item } });
    },
  },
  filters: {
    filterTitle(val) {
      if(!val) return ''
      return  val.length > 40 ? val.substr(0,40) + '...' : val
    }
  }
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/studentsEndCSS/home";
.consult_wrap {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .hh_consult_title {
    width: 60%;
    .hh_litle {
      height: 45px;
      width: 100%;
      line-height: 45px;
      position: relative;
      .hh_title_info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        cursor: pointer;
        .hh_txt {
          text-align: left;
        }
        .hh_times {
          text-align: right;
        }
      }
      .hh_style {
        background: #e8f1ff;
        color: #3f8cff;
        border-radius: 19px;
      }
      .hh_icon {
        width: 10px;
        height: 10px;
        display: none;
        position: absolute;
        top: 0;
        right: -20px;
      }
      .hh_showicon {
        display: block;
      }
    }
  }
  .hh_img {
    margin-top: 8px;
    width: 390px;
    height: 239px;
    cursor: pointer;
  }
}
/* 加载前 */
::v-deep .el-image__placeholder {
  background: url("../../assets/gif.gif") no-repeat 50% 50% !important;
  background-size: 50% !important;
}
</style>
