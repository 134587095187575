<template>
  <!-- 首页轮播图 -->
  <div class="container">
    <div class="top_banner common_inner">
      <div class="banner_nav">
        <div class="nav_line" v-for="(item, index) in images" :key="index" >
          <p class="hh_title" @click.stop="toCategoryOne(index,item)">
            <img :src="require('@/assets/img/studentImg/home/' + item.src + '.png')" />
            <span>{{ item.name }}</span>
            <i class="el-icon-arrow-right"></i>
          </p>
          <p class="hh_con">
            <span @click.stop="toCategoryTwo(index,item,indexs,items.medicalId)" v-for='(items,indexs) in item.children' :key="indexs">{{ items.medicalName }}</span>
          </p>
        </div>
        <p class="hh_more">
          <i class="el-icon-folder-add"></i>
          <span @click="toCategory">全部</span>
        </p>
      </div>
      <el-carousel class="banner" height="360px" arrow="never" trigger="click">
        <el-carousel-item v-for="item in bannelImg" :key="item.id">
          <img 
            class="banner-img" 
            :src="item.bannerImg" 
            alt 
            @click="handelBanner(item)"
            />
        </el-carousel-item>
      </el-carousel>
    </div>
    <courseVerificationCode :courseId="courseId" />
  </div>
</template>
<script>
import { EventBus } from '@/assets/js/eventBus'
import API from '@/apis/studentsEndAPI/liveListAPI'
import courseVerificationCode from "@/components/backStageComponent/courseVerificationCode"
export default {
  name: 'carousel',
  components: {
    courseVerificationCode
  },  
  data() {
    return {
      departmentList: [],
      images: [
        {
          name: '内科',
          src: 'neike-icon',
          children: [],
          medicalId: '',
        },
        {
          name: '外科',
          src: 'waike-icon',
          children: [],
          medicalId: '',
        },
        {
          name: '妇产科',
          src: 'fuchan-icon',
          children: [],
          medicalId: '',
        },
        {
          name: '皮肤性病科',
          src: 'erke-icon',
          children: [],
          medicalId: '',
        },
        {
          name: '耳鼻咽喉科',
          src: 'jizhen-icon',
          children: [],
          medicalId: '',
        },
      ],
      showArea: '1',//1:web端、2:app端、3:h5端
      bannelImg: [],
      query: {
        id: '',
        code: '',
      },
      courseId: '',
    }
  },
  mounted() {
    this.getMedicalList()
    this.getBannerImg()
  },
  methods: {
    async getMedicalList() {
      let status = '1'
      let data = await API.getMedicalList(status)
      this.departmentList = data.slice(0, 5)
      this.images.forEach((e, ind) => {
        this.departmentList.forEach((ele, index) => {
          if (ind == index) {
            e.name = ele.medicalName
            e.children = ele.children.slice(0, 3)
            e.medicalId = ele.medicalId
          }
        })
      })
    },
    async getBannerImg() {
      let data = await API.bannerlist(this.showArea)
      this.bannelImg = data
    },
    handelBanner(item) {
      let url = item.jumpUrl
      let limit = this.getQueryString(url,'id','courseType','courseLimits','state')
      this.query.id = limit.courseId  || ''
      if(limit.state == '未发布' || limit.state == '已下架' || limit.state == '审核中'||limit.state == '已驳回') {
        this.$message.error('跳转的内容无效！')
        return false
      }else {
        //轮播课程携带state 判断是否未发布还是已下架
        if(limit.courseLimits == 0) {
          this.navigator(limit.courseType,this.query)
        }else if(limit.courseLimits == 1) {//直播点播
          if (!sessionStorage.getItem('educationToken')) {
            this.$store.state.isLogin = true
          } else {
            this.navigator(limit.courseType,this.query)
          }
        }else if(limit.courseLimits == 2) {//验证码
          this.courseId = limit.courseId
          EventBus.$emit('courseId',limit.courseId)
          this.$store.state.isVerifi = true
        }else if( limit.courseLimits == 'consultDetail') {//资讯
          this.$router.push({
            path: '/consultDetail',
            query: { id: limit.courseId}
          })
        }else if( limit.courseLimits == 'url') {//链接
          window.open(item.jumpUrl, '_blank')
        }else {//无链接
          return false
        }
      }
    },   
    navigator(type, item) {
      if (type == '1') {
        this.$router.push({
          path: '/liveDetail',
          query: item,
        })
      } else {
        this.$router.push({
          path: '/dibbleDetail',
          query: item,
        })
      }
    },
    // 全部
    toCategory() {
       this.$router.push(`/searchList?all=${true}`)
       this.$EventBus.$emit('flag', true)
    },
    // 一级分类
    toCategoryOne(index,item){
      this.$router.push(`/searchList?index=${index}&medicalId=${item.medicalId}&teaName=${item.name}`)
      this.$EventBus.$emit('flag', true)
    },
    toCategoryTwo(index,item,indexs,medicalId) {
     this.$router.push({name: 'searchList',params: {index,item,indexs,medicalId}})
     this.$EventBus.$emit('flag', true)
    },
    getQueryString(url,courseId ,courseType,courseLimits,state) {//获取地址参数
      if(!url) {
        return {
          courseId: '',
          courseType: '',
          courseLimits: 'nourl',
          state: '无链接'
        }
      }else {
        if(url.indexOf('?') != -1) {
          let p = url.split('?')[1]
          let params = new URLSearchParams(p)
          if(params.get('type') == 'consultDetail') {//轮播图地址类型是咨询
            return  {
              courseId: params.get('id'),
              courseLimits: params.get('type'),
              state: params.get('state')
            }
          }else {
            return {
              courseId: params.get(courseId),
              courseType: params.get(courseType),
              courseLimits: params.get(courseLimits),
              state: params.get(state)
            }
          }
        }else { 
          return {
            courseId: '',
            courseType: '',
            courseLimits: 'url',
            state: '链接'
          }
        }
      }
      
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
	width: 100%;
	background: #f7faff;
}
.top_banner {
	display: flex;
	padding: 20px 0 30px 0;
	.banner {
		flex: 1;
		width: 958px;
		height: 360px;
		img {
			height: 100%;
      cursor: pointer;
		}
	}

	.banner_nav {
		top: 15px;
		left: 15px;
		margin-right: 10px;
		width: 230px;
		height: 360px;
		border-radius: 6px;
		background-color: rgba(63, 140, 255, .7);
		background-image: url("../../assets/img/studentImg/home/back-icon.png");

		.nav_line {
			padding: 9px 10px 0 10px;
			color: rgba(255, 255, 255, 1);
			.hh_title {
				display: flex;
				justify-content: space-between;
				padding: 2px 10px;
				font-size: 14px;
				img {
					width: 20px;
					height: 19px;
					vertical-align: middle;
				}
				span {
					overflow: hidden;
					width: 70px;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					cursor: pointer;
				}
				i {
					padding: 2px 40px 0 20px;
					cursor: pointer;
				}
			}
			.hh_con {
				overflow: hidden;
				padding-top: 6px;
				padding-bottom: 9px;
				border-bottom: 1px solid #fff;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 12px;
				span {
					padding: 9px 5px 0 5px;
					cursor: pointer;
				}
			}
		}
		.hh_more {
			margin-top: 11px;
			color: #fff;
			text-align: center;
			span {
				cursor: pointer;
			}
		}
	}
}

</style>
